module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148251992-9","head":false,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"unitpedia.com","short_name":"unitpedia","icon":"logo/favicon-32x32.png","icons":[{"src":"/logo/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/logo/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","theme_color":"#333333","background_color":"#ffffff","display":"standalone","orientation":"portrait","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2fb09ab975fa74be402e845caacbfc82"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
