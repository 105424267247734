/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// const loadableReady = require("@loadable/component").loadableReady

// exports.replaceHydrateFunction = () => {
//   return (element, container, callback) => {
//     loadableReady(() => {
//       ReactDOM.render(element, container, callback)
//     })
//   }
// }

exports.onRouteUpdateDelayed = () => {
  document.getElementById("lodProgress").style.display = "block"
}

// exports.onClientEntry = () => {
//   console.log("We've started!")
//   // callAnalyticsAPI()
// }
exports.onInitialClientRender = () => {
  ;(() => {
    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "production"
    ) {
      const sct = document.createElement("script"),
        sctHl = document.getElementsByTagName("script")[0]
      sct.type = "text/javascript"
      sct.async = true
      sct.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      sctHl.parentNode.insertBefore(sct, sctHl)
    }
  })()
  // console.log("ReactDOM.render has executed")
}
// const React = require("react")
// const Layout = require("./src/components/layout").default

// exports.wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }

// exports.onInitialClientRender = () => {
//   if (
//     "onGatsbyInitialClientRender" in window &&
//     typeof window.onGatsbyInitialClientRender === "function"
//   ) {
//     window.onGatsbyInitialClientRender()
//   }
// }

// exports.onRouteUpdate = () => {
//   if (
//     "onGatsbyRouteUpdate" in window &&
//     typeof window.onGatsbyRouteUpdate === "function"
//   ) {
//     window.onGatsbyRouteUpdate()
//   }
// }
